import Vue from 'vue'
import Router from 'vue-router'

const Index = () => import('@/views/Index')

// Containers
const TheContainer = () => import('@/containers/TheContainer')

// User
const UserInfo = () => import('@/views/user/Info')
const UserDomainList = () => import('@/views/user/DomainList')
const UserDomainView = () => import('@/views/user/DomainView')

//Router
const RouterList = () => import('@/views/router/List')
const RouterView = () => import('@/views/router/View')
const RouterAdd = () => import('@/views/router/Add')
const RouterAuth = () => import('@/views/router/Auth')

//Port Forward
const PortForwardList = () => import('@/views/portforward/PortForwardList')

//CMDB
const CMDBDeviceList = () => import('@/views/cmdb/DeviceList')
const CMDBDAddDevice = () => import('@/views/cmdb/AddDevice')
const CMDBDViewDevice = () => import('@/views/cmdb/ViewDevice')

//DDNS
const DDNSList = () => import('@/views/ddns/List')
const DDNSAdd = () => import('@/views/ddns/Add')

// Auth
const Login = () => import('@/views/auth/Login')
const Logout = () => import('@/views/auth/Logout')
const Register = () => import('@/views/auth/Register')
const PageNotFound = () => import('@/views/404')



Vue.use(Router)

export default new Router({
  mode: 'history', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
})

function configRoutes () {
  return [
    {
      path: '/',
      redirect: '/index',
      name: 'Home',
      component: TheContainer,
      children: [
        {
          path: 'index',
          name: 'Index',
          component: Index
        },

      ]
    },
    {
      path: '/router',
      redirect: '/router',
      name: 'Router',
      component: TheContainer,
      children: [
        {
          path: '',
          name: 'List',
          component: RouterList
        },
        {
          path: 'view/:id',
          name: 'View',
          component: RouterView
        },
        {
          path: 'add',
          name: 'Add',
          component: RouterAdd
        },
        {
          path: 'auth',
          name: 'Share Router',
          component: RouterAuth
        },
      ]
    },
    {
      path: '/port-forward',
      redirect: '/port-forward',
      name: 'Port Forward',
      component: TheContainer,
      children: [
        {
          path: '',
          name: 'List',
          component: PortForwardList
        },

      ]
    },
    {
      path: '/cmdb',
      redirect: '/cmdb/device',
      name: 'Router',
      component: {
        render (c) { return c('router-view') }
      },
      children: [
        {
          path: 'device',
          name: 'Device',
          component: TheContainer,
          children: [
            {
              path: '',
              name: 'List',
              component: CMDBDeviceList
            },
            {
              path: 'add',
              name: 'AddDevice',
              component: CMDBDAddDevice
            },
            {
              path: 'view/:id',
              name: 'View',
              component: CMDBDViewDevice
            },
          ]
        }
      ]
    },
    {
      path: '/ddns',
      redirect: '/ddns/list',
      name: 'Router',
      component: TheContainer,
      children: [
        {
          path: 'list',
          name: 'List',
          component: DDNSList
        },
        {
          path: 'add',
          name: 'AddDevice',
          component: DDNSAdd
        }
      ]
    },
    {
      path: '/auth',
      redirect: '/index',
      name: 'Auth',
      component: {
        render (c) { return c('router-view') }
      },
      children: [
        {
          path: 'login',
          name: 'Login',
          component: Login
        },
        {
          path: 'register',
          name: 'Register',
          component: Register
        },
        {
          path: 'logout',
          name: 'Logout',
          component: Logout
        }
      ]
    },
    {
      path: '/user',
      redirect: '/user/info',
      name: 'User',
      component: {
        render (c) { return c('router-view') }
      },
      children: [
        {
          path: 'info',
          redirect: '/user/info',
          component: TheContainer,
          children: [
            {
              path: '',
              name: 'Info',
              component: UserInfo
            },
          ]
        },
        {
          path: 'domain',
          name: 'Domain',
          redirect: '/user/domain',
          component: TheContainer,
          children: [
            {
              path: '',
              component: UserDomainList
            },
            {
              path: ':id',
              name: 'View',
              component: UserDomainView
            }
          ]
        },
      ]
    },
    {
      path: '/404',
      name: 'Page Not Found',
      component: PageNotFound
    },
    {
      path: '*',
      name: 'Page Not Found',
      component: PageNotFound
    }
  ]
}

