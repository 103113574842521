<template>
  <CCard>
    <CCardHeader>
      {{ router.name }}({{ router.dns_name }})
    </CCardHeader>
    <CCardBody>
      <CRow>
        <CCol sm="6">
          <CCard>
            <CCardHeader>
              Router Info
            </CCardHeader>
            <CCardBody>
              <CRow>
                <CCol sm="12">
                  <CInput
                    :value.sync="router.name"
                  >
                    <template #prepend-content>
                      <CIcon name="cil-comment-bubble" />
                      &nbsp;Name
                    </template>
                  </CInput>
                </CCol>
                <CCol sm="12">
                  <CInput
                    :value.sync="router.dns_name"
                  >
                    <template #prepend-content>
                      <CIcon name="cil-devices" />
                      &nbsp;DNS Name
                    </template>
                  </CInput>
                </CCol>
                <CCol sm="12">
                  <CInput
                    :value.sync="router.router_ip"
                  >
                    <template #prepend-content>
                      <CIcon name="cil-router" />
                      &nbsp;Router IP
                    </template>
                  </CInput>
                </CCol>
                <CCol sm="12">
                  <CInput
                    :value.sync="router.cmdb.uuid"
                    disabled
                  >
                    <template #prepend-content>
                      <CIcon name="cil-tag" />
                      &nbsp;Device UUID
                    </template>
                  </CInput>
                </CCol>
              </CRow>
            </CCardBody>
            <CCardFooter>
              <CButton
                color="primary"
                square
                @click="editRouterSetting"
              >
                Submit
              </CButton>
            </CCardFooter>
          </CCard>
          <CCard>
            <CCardHeader>
              Connection Info
            </CCardHeader>
            <CCardBody>
              <CRow>
                <CCol sm="12">
                  <CInput
                    :value.sync="router.host"
                  >
                    <template #prepend-content>
                      <CIcon name="cil-laptop" />
                      &nbsp;Api Connect Host
                    </template>
                  </CInput>
                </CCol>
                <CCol sm="12">
                  <CInput
                    :value.sync="router.port"
                  >
                    <template #prepend-content>
                      <CIcon name="cil-rss" />
                      &nbsp;Port
                    </template>
                  </CInput>
                </CCol>
                <CCol sm="12">
                  <CInput
                    :value.sync="router.username"
                  >
                    <template #prepend-content>
                      <CIcon name="cil-user" />
                      &nbsp;Username
                    </template>
                  </CInput>
                </CCol>
                <CCol sm="12">
                  <CInput
                    :value.sync="router.password"
                  >
                    <template #prepend-content>
                      <CIcon name="cil-lock-locked" />
                      &nbsp;Password
                    </template>
                  </CInput>
                </CCol>
                <CCol sm="12">
                  <CInput
                    :value.sync="router.location"
                  >
                    <template #prepend-content>
                      <CIcon name="cil-location-pin" />
                      &nbsp;Location
                    </template>
                  </CInput>
                </CCol>
                <CCol sm="12">
                  <CInput
                    :value.sync="router.isp"
                  >
                    <template #prepend-content>
                      <CIcon name="cil-sim" />
                      &nbsp;ISP
                    </template>
                  </CInput>
                </CCol>
              </CRow>
            </CCardBody>
            <CCardFooter>
              <CButtonGroup>
                <CButton
                  color="primary"
                  square
                  @click="editRouterSetting()"
                >
                  Submit
                </CButton>
                <CButton
                  v-if="!router.is_controlling"
                  color="danger"
                  square
                  @click="initRouter()"
                >
                  Init Router
                </CButton>
              </CButtonGroup>
            </CCardFooter>
          </CCard>
          <CCard v-if="this.router.dhcp_address.length > 0">
            <CCardHeader>
              Dhcp Address List
            </CCardHeader>
            <CCardBody>
              <CDataTable
                small
                fixed
                sorter
                :items-per-page="5"
                :fields="dhcpAddressListFields"
                :items="router.dhcp_address"
                :noItemsView="{ noItems: 'No Item~' }"
                pagination
                :sorterValue="{column: 'interface_name'}"
              >
                <template #cmdbDeviceName="{item}">
                  <td class="py-2">
                    {{ item.cmdb_device.name }}
                  </td>
                </template>
                <template #action="{item}">
                  <td class="py-2">
                    <CButtonGroup>
                      <CButton
                        color="info"
                        square
                        @click="$router.push('/cmdb/device/view/'+item.cmdb_device.id);"
                      >
                        Go CMDB Page
                      </CButton>
                    </CButtonGroup>
                  </td>
                </template>
              </CDataTable>
            </CCardBody>
          </CCard>
        </CCol>
        <CCol sm="6">
          <CCard>
            <CCardHeader>
              Interface List
            </CCardHeader>
            <CCardBody>
              <CDataTable
                small
                fixed
                sorter
                :items-per-page="5"
                :fields="interfaceListFields"
                :items="interfaceListItem"
                :noItemsView="{ noItems: 'No Item~' }"
                pagination
                :sorterValue="{column: 'interface_name'}"
              >
                <template #setting="{item}">
                  <td class="py-2">
                    <CButtonGroup>
                      <CButton
                        color="success"
                        square
                        v-if="!item.is_dnat_port"
                        @click="convertDNatPort(item.id)"
                      >
                        Mark DNat Port
                      </CButton>
                      <CButton
                        color="danger"
                        square
                        v-if="item.is_dnat_port"
                        @click="convertDNatPort(item.id)"
                      >
                        Mark Normal Port
                      </CButton>
                    </CButtonGroup>
                  </td>
                </template>
              </CDataTable>
            </CCardBody>
            <CCardFooter>
              <CRow>
                <CCol sm="8">
                  <CCardText>Last sync interface time:{{ router.synced_at | dateFormat }}</CCardText>
                </CCol>
                <CCol sm="4">
                  <CButton
                    color="primary"
                    square
                    @click="syncRouterInterface"
                  >
                    <CIcon name="cil-sync" />
                    Sync Now
                  </CButton>
                </CCol>
              </CRow>
            </CCardFooter>
          </CCard>
          <CCard>
            <CCardHeader>
              Dhcp Pool List
            </CCardHeader>
            <CCardBody>
              <CDataTable
                small
                fixed
                sorter
                :items-per-page="5"
                :fields="dhcpPoolListFields"
                :items="router.dhcp_server"
                :noItemsView="{ noItems: 'No Item~' }"
                pagination
                :sorterValue="{column: 'interface_name'}"
              >
                <template #action="{item}">
                  <td class="py-2">
                    <CButtonGroup>
                      <CButton
                        color="danger"
                        square
                        @click="deleteRouterDhcpServer(item.id)"
                      >
                        Delete
                      </CButton>
                    </CButtonGroup>
                  </td>
                </template>
              </CDataTable>
            </CCardBody>
            <CCardFooter>
              <CCardText>Add dhcp server</CCardText>
              <CRow>
                <CCol sm="12">
                  <CInput
                    :value.sync="server_name"
                    placeholder="Dhcp server name.eg:server-xxx"
                  >
                    <template #prepend-content>
                      <CIcon name="cil-terminal" />
                      &nbsp;Dhcp Server Name
                    </template>
                  </CInput>
                </CCol>
                <CCol sm="12">
                  <CInput
                    :value.sync="addresses"
                    placeholder="x.x.x.x/xx"
                  >
                    <template #prepend-content>
                      <CIcon name="cil-list" />
                      &nbsp;Addresses block
                    </template>
                  </CInput>
                </CCol>
                <CCol sm="12">
                  <CButton
                    color="success"
                    square
                    @click="addRouterDhcpServer"
                  >
                    Add
                  </CButton>
                </CCol>
              </CRow>
            </CCardFooter>
          </CCard>
        </CCol>
      </CRow>
    </CCardBody>
    <CToaster :autohide="3000">
      <template v-for="toast in fixedToasts">
        <CToast
          :key="'toast' + toast"
          :show="true"
          :color="alertLevel"
          header="Alert"
        >
          {{ returnMsg }}
        </CToast>
      </template>
    </CToaster>
    <CModal
      title="Request Message"
      color="primary"
      :show.sync="requestMsgModal"
    >
      {{ requestMsg }}
      <template #footer>
        <CButton @click="requestMsgModal=false" color="primary">Close</CButton>
      </template>
    </CModal>
  </CCard>
</template>

<script>
export default {
  name: "View",
  data() {
    const interfaceListFields = [
      { key: "interface_name", label: "Interface Name" },
      { key: "type", label: "Type" },
      {
        key: "setting",
        label: "Setting",
        sorter: false,
        filter: false,
        _style: "width: 30%"
      }
    ];
    const dhcpPoolListFields = [
      { key: "server_name", label: "Pool Name" },
      { key: "addresses", label: "Addresses Block" },
      {
        key: "action",
        label: "Action",
        sorter: false,
        filter: false,
        _style: "width: 30%"
      }
    ];
    const dhcpAddressListFields = [
      { key: "ip", label: "IP Address" },
      { key: "cmdbDeviceName", label: "Device Name" },
      {
        key: "action",
        label: "Action",
        sorter: false,
        filter: false,
        _style: "width: 30%"
      }
    ];
    return {
      fixedToasts: 0,
      returnMsg: "",
      alertLevel: "success",
      requestMsgModal: false,
      requestMsg: null,
      id: this.$route.params.id,
      router: [],
      interfaceListFields,
      interfaceListItem: [],
      dhcpPoolListFields,
      server_name: null,
      addresses: null,
      dhcpAddressListFields
    };
  },
  mounted: function() {
    this.onLoadPage();
  },
  methods: {
    onLoadPage: function() {
      this.$axios.get("user/router/" + this.id + "/view", {})
        .then((response) => {
          if (response.data.code === 200) {
            this.router = response.data.router;
          }
        });
      this.$axios.get("user/router/" + this.id + "/interfaces", {})
        .then((response) => {
          if (response.data.code === 200) {
            this.interfaceListItem = response.data.interfaces.map(item => {
              return {
                ...item
              };
            });
          }
        });
    },
    syncRouterInterface: function() {
      this.$axios.get("user/router/" + this.id + "/interfaces/sync", {})
        .then((response) => {
          if (response.data.code === 200) {
            this.requestMsg = response.data.msg;
            this.requestMsgModal = true;
            this.onLoadPage();
          } else {
            this.requestMsg = "Sync Error";
            this.requestMsgModal = true;
          }
        });
    },
    initRouter: function() {
      this.$axios.post("user/router/" + this.id + "/init", {})
        .then((response) => {
          if (response.data.code === 200) {
            this.requestMsg = response.data.msg;
            this.requestMsgModal = true;
            this.onLoadPage();
          } else {
            this.requestMsg = "Init Error";
            this.requestMsgModal = true;
          }
        });
    },
    editRouterSetting: function() {
      this.$axios.post("user/router/" + this.id + "/edit", {
        name: this.router.name,
        router_ip: this.router.router_ip,
        dns_name: this.router.dns_name,
        host: this.router.host,
        port: this.router.port,
        username: this.router.username,
        password: this.router.password,
        location: this.router.location,
        isp: this.router.isp
      })
        .then((response) => {
          if (response.data.code === 200) {
            this.requestMsg = response.data.msg;
            this.requestMsgModal = true;
            this.onLoadPage();
          } else {
            this.requestMsg = response.data.msg;
            this.requestMsgModal = true;
          }
        });
    },
    convertDNatPort: function(id) {
      this.$axios.post("user/router/interface/" + id + "/mark-dnat", {
        id: id
      })
        .then((response) => {
          if (response.data.code === 200) {
            this.requestMsg = response.data.msg;
            this.requestMsgModal = true;
            this.onLoadPage();
          } else {
            this.requestMsg = response.data.msg;
            this.requestMsgModal = true;
          }
        });
    },
    addRouterDhcpServer: function() {
      this.$axios.post("user/router/dhcp-server/add", {
        router_id: this.id,
        server_name: this.server_name,
        addresses: this.addresses
      })
        .then((response) => {
          if (response.data.code === 200) {
            this.requestMsg = response.data.msg;
            this.requestMsgModal = true;
            this.onLoadPage();
          } else {
            this.requestMsg = response.data.msg;
            this.requestMsgModal = true;
          }
        });
    },
    deleteRouterDhcpServer: function(dhcp_server_id) {
      this.$axios.delete("user/router/dhcp-server/"+ dhcp_server_id +"/delete", {})
        .then((response) => {
          if (response.data.code === 200) {
            this.requestMsg = response.data.msg;
            this.requestMsgModal = true;
            this.onLoadPage();
          } else {
            this.requestMsg = response.data.msg;
            this.requestMsgModal = true;
          }
        });
    },
  }
};
</script>