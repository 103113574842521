<template>
  <div>
    <CCard>
      <CCardHeader>
        Router List
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol sm="12">
            <CDataTable
              hover
              small
              fixed
              table-filter
              sorter
              items-per-page-select
              :items-per-page="15"
              :fields="routerListFields"
              :items="routerListItem"
              :noItemsView="{ noItems: 'No Item~' }"
              pagination
            >
              <template #action="{item}">
                <td class="py-2">
                  <CButtonGroup>
                    <CButton
                      color="primary"
                      square
                      @click="$router.push('/router/view/'+item.id)"
                    >
                      View
                    </CButton>
                    <CButton
                      color="danger"
                      square
                      @click="deleteRouter(item.id)"
                    >
                      Delete
                    </CButton>

                  </CButtonGroup>
                </td>
              </template>
            </CDataTable>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>

    <CCard>
      <CCardHeader>
        Authed Router List
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol sm="12">
            <CDataTable
              hover
              small
              fixed
              table-filter
              sorter
              items-per-page-select
              :items-per-page="15"
              :fields="authedRouterListFields"
              :items="authedRouterListItem"
              :noItemsView="{ noItems: 'No Item~' }"
              pagination
            >
              <template #action="{item}">
                <td class="py-2">
                  <CButtonGroup>
                    <CButton
                      color="danger"
                      square
                      @click="deleteRouterAuth(item.record_id)"
                    >
                      Remove Authorization
                    </CButton>

                  </CButtonGroup>
                </td>
              </template>
            </CDataTable>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>

    <CToaster :autohide="3000">
      <template v-for="toast in fixedToasts">
        <CToast
          :key="'toast' + toast"
          :show="true"
          :color="alertLevel"
          header="Alert"
        >
          {{ returnMsg }}
        </CToast>
      </template>
    </CToaster>
    <CModal
      title="Request Message"
      color="primary"
      :show.sync="requestMsgModal"
    >
      {{ requestMsg }}
      <template #footer>
        <CButton @click="requestMsgModal=false" color="primary">Close</CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
export default {
  name: "List",
  data() {
    const routerListFields = [
      { key: "id", label: "ID" },
      { key: "name", label: "Name" },
      { key: "dns_name", label: "Host" },
      { key: "location", label: "Location" },
      { key: "isp", label: "ISP" },
      {
        key: "action",
        label: "Action",
        sorter: false,
        filter: false,
        _style: "width: 10%"
      }
    ];

    const authedRouterListFields = [
      { key: "id", label: "ID" },
      { key: "owner", label: "Router Owner" },
      { key: "name", label: "Name" },
      { key: "dns_name", label: "Host" },
      { key: "location", label: "Location" },
      { key: "isp", label: "ISP" },
      {
        key: "action",
        label: "Action",
        sorter: false,
        filter: false,
        _style: "width: 10%"
      }
    ];

    return {
      fixedToasts: 0,
      returnMsg: "",
      alertLevel: "success",
      requestMsgModal: false,
      requestMsg: null,
      routerListFields,
      routerListItem: [],
      authedRouterListFields,
      authedRouterListItem:[]
    };
  },
  mounted: function() {
    this.onLoadPage();
  },
  methods: {
    onLoadPage: function() {
      this.$axios.get("user/router", {})
        .then((response) => {
          if (response.data.code === 200) {
            this.routerListItem = response.data.userRouter.map(item => {
              return {
                ...item
              }
            });
            this.authedRouterListItem = response.data.authedRouter.map(item => {
              let owner = item.router.owner.username;
              return {
                ...item.router,
                owner,
                record_id: item.id
              }
            });
          }
        });
    },
    deleteRouterAuth: function(id) {
      this.$axios.delete("user/router/auth/"+id+"/delete", {})
        .then((response) => {
          this.requestMsg = response.data.msg;
          this.requestMsgModal = true;
          this.onLoadPage();
        });
    },
    deleteRouter: function(id) {
      this.$axios.delete("user/router/"+id+"/delete", {})
        .then((response) => {
          this.requestMsg = response.data.msg;
          this.requestMsgModal = true;
          this.onLoadPage();
        });
    },
  }
};
</script>