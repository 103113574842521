<template>
  <CCard>
    <CCardHeader>
      Add DDNS
    </CCardHeader>
    <CCardBody>
      <CRow>
        <CCol sm="6">
          <CRow>
            <CCol sm="12">
              <CInput
                prepend="Search Device"
                placeholder="Search keywords"
                type="text"
                :value.sync="deviceSearchKey"
              >
                <template #append>
                  <CButton
                    color="primary"
                    square
                    @click="isShowSearchResult=true;
                    searchDevice();
                    deviceSearchKey=null;"
                  >
                    Search
                  </CButton>
                </template>
              </CInput>
            </CCol>
            <CCol sm="12">
              <CDataTable
                v-if="isShowSearchResult"
                hover
                small
                fixed
                table-filter
                :items-per-page="3"
                :fields="deviceSearchResultFields"
                :items="deviceSearchResultList"
                :noItemsView="{ noItems: 'No Item~' }"
                pagination
              >
                <template #action="{item}">
                  <td class="py-2">
                    <CButtonGroup>
                      <CButton
                        color="primary"
                        square
                        @click="selectDeviceId=item.id;
                        selectDeviceName=item.name;
                        isShowSearchResult=false;
                        loadCMDBDeviceInterfaces()"
                      >
                        Select
                      </CButton>
                    </CButtonGroup>
                  </td>
                </template>
              </CDataTable>
            </CCol>
            <CCol sm="12">
              <CInput
                disabled
                prepend="Selected Device"
                placeholder="Search and select a device"
                :value.sync="selectDeviceName"
              />
            </CCol>
            <CCol sm="12">
              <CSelect
                :options="cmdbInterfacesOption"
                prepend="CMDB Interfaces"
                :value.sync="selectDeviceInterface"
              />
            </CCol>
            <CCol sm="12">
              <CSelect
                placeholder="DDNS Type"
                :options="ddnsTypeOption"
                prepend="DDNS Type"
                :value.sync="ddnsType"
              />
            </CCol>
            <CCol sm="12">
              <CSelect
                :options="['A','AAAA']"
                prepend="DNS Type"
                :value.sync="dnsType"
              />
            </CCol>
            <CCol sm="12">
              <CInput
                placeholder="Like www(without last .)"
                prepend="Domain prefix"
                type="text"
                :value.sync="domainPrefix"
              >
              </CInput>
            </CCol>
            <CCol sm="12">
              <CSelect
                :options="domainOption"
                prepend="DDNS Domain"
                @change="loadProviderLines()"
                :value.sync="domain"
              />
            </CCol>
            <CCol sm="12">
              <CInput
                placeholder="Can be null and generate later"
                prepend="Provider record id"
                type="text"
                :value.sync="providerRecordID"
              >
              </CInput>
            </CCol>
            <CCol sm="12">
              <CSelect
                :options="providerLinesOptions"
                prepend="Provider Lines"
                :value.sync="providerLine"
              />
            </CCol>
            <CCol sm="12">
              <CButton
                color="primary"
                square
                @click="addDDNS"
              >
                Submit
              </CButton>
            </CCol>
          </CRow>
        </CCol>
      </CRow>
    </CCardBody>
    <CToaster :autohide="3000">
      <template v-for="toast in fixedToasts">
        <CToast
          :key="'toast' + toast"
          :show="true"
          :color="alertLevel"
          header="Alert"
        >
          {{ returnMsg }}
        </CToast>
      </template>
    </CToaster>
    <CModal
      title="Request Message"
      color="primary"
      :show.sync="requestMsgModal"
    >
      {{ requestMsg }}
      <template #footer>
        <CButton @click="requestMsgModal=false" color="primary">Close</CButton>
      </template>
    </CModal>
  </CCard>
</template>

<script>
const deviceSearchResultFields = [
  { key: "id", label: "ID" },
  { key: "name", label: "Device Name" },
  { key: "hostname", label: "DDNS Type" },
  { key: "main_ip", label: "Hostname" },
  {
    key: "action",
    label: "Action",
    sorter: false,
    filter: false,
    _style: "width: 20%"
  }
];

export default {
  name: "AddDDNS",
  data() {
    return {
      fixedToasts: 0,
      returnMsg: "",
      alertLevel: "success",
      requestMsgModal: false,
      requestMsg: null,
      deviceSearchKey: null,
      deviceSearchResultFields,
      ddnsTypeOption: [],
      ddnsType: null,
      isShowSearchResult: false,
      deviceSearchResultList: [],
      selectDeviceName: null,
      selectDeviceId: null,
      domain: null,
      domainPrefix: null,
      domainProviderMap: {},
      domainOption: [],
      providerLinesOptions: [{ value: null, label: "Select domain first", disabled: true }],
      providerLine: null,
      providerRecordID: null,
      cmdbInterfacesOption: [{ value: null, label: "Select device first", disabled: true }],
      selectDeviceInterface: null,
      dnsType: "A"
    };
  },
  mounted: function() {
    this.onLoadPage();
  },
  methods: {
    onLoadPage: function() {
      this.$axios.get("user/ddns/type", {})
        .then((response) => {
          if (response.data.code === 200) {
            this.ddnsTypeOption = response.data.type.map(item => {
              return {
                label: item.type_name,
                value: item.id
              };
            });
            this.ddnsTypeOption.unshift(
              { value: null, label: "Select ddns type", disabled: true }
            );
          }
        });
      this.$axios.get("user/domain", {})
        .then((response) => {
          if (response.data.code === 200) {
            this.domainOption = response.data.domains.map(item => {
              this.domainProviderMap[item.id] = item.domain_provider_id;
              return {
                label: item.domain,
                value: item.id
              };
            });
            this.domainOption.unshift(
              { value: null, label: "Select a domain", disabled: true }
            );
          }
        });
    },
    searchDevice: function() {
      this.$axios.post("user/cmdb/device/search", {
        search_key: this.deviceSearchKey
      })
        .then((response) => {
          this.deviceSearchResultList = response.data.device;
        });
    },
    loadProviderLines: function() {
      this.$axios.get("user/domain/provider/lines", {
        params: {
          provider_id: this.domainProviderMap[this.domain]
        }
      })
        .then((response) => {
          if (response.data.code === 200) {
            this.providerLinesOptions = response.data.lines.map(item => {
              return {
                label: item.line_name,
                value: item.id
              };
            });
            this.providerLinesOptions.unshift(
              { value: null, label: "Select a provider line", disabled: true }
            );
          }
        });
    },
    loadCMDBDeviceInterfaces: function() {
      this.$axios.get("user/cmdb/device/" + this.selectDeviceId + "/interfaces", {

      })
        .then((response) => {
          if (response.data.code === 200) {
            this.cmdbInterfacesOption = response.data.interfaces.map(item => {
              return {
                label: item.interface_name,
                value: item.id
              };
            });
            this.cmdbInterfacesOption.unshift(
              { value: null, label: "Select a interface", disabled: true }
            );
          }
        });
    },
    addDDNS: function() {
      this.$axios.post("user/ddns/add", {
        device_id: this.selectDeviceId,
        interface_id: this.selectDeviceInterface,
        domain_prefix: this.domainPrefix,
        domain_id: this.domain,
        ddns_type: this.ddnsType,
        provider_record_id: this.providerRecordID,
        provider_line_id: this.providerLine,
        dns_type: this.dnsType
      })
        .then((response) => {
          this.requestMsg = response.data.msg;
          this.requestMsgModal = true;
        });
    }
  }
};
</script>