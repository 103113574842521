<template>
  <div>
    <CCard>
      <CCardHeader>
        Domain Record List
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol sm="6">
            <CInput
              prepend="Search domain"
              :value.sync="searchKey"
            >
              <template #append>
                <CButton
                  color="primary"
                  square
                  @click="activePage=1;loadRecords(activePage);"
                >
                  Search
                </CButton>
              </template>
            </CInput>
          </CCol>
          <CCol sm="12">
            <CDataTable
              hover
              small
              fixed
              :fields="dnsRecordListFields"
              :items="recordList"
            >
              <template #value="{item}">
                <td v-if="item.value.length < 100">
                  {{ item.value }}
                </td>
                <td v-if="item.value.length >= 100">
                  <CButton
                    color="primary"
                    square
                    @click="requestMsgModal=true;requestMsg=item.value;"
                  >
                    ShowValue
                  </CButton>
                </td>
              </template>
              <template #action="{item, index}">
                <td class="py-2">
                  <CButtonGroup>
                    <CButton
                      color="primary"
                      square
                      :variant="Boolean(item._toggled) ? 'outline' : ''"
                      @click="toggleEditRecord(item, index)"
                      :disabled="!isSupportEditCheck(item.type)"
                    >
                      Edit
                    </CButton>
                    <CButton
                      color="info"
                      square
                      @click="requestMsg=item.recordID;requestMsgModal=true;"
                    >
                      ViewID
                    </CButton>
                  </CButtonGroup>
                </td>
              </template>
              <template #details="{item}">
                <CCollapse :show="Boolean(item._toggled)" :duration="collapseDuration">
                  <CCardBody>
                    <CRow>
                      <CCol sm="3">
                        <CInput
                          prepend="Record"
                          type="text"
                          :value.sync="item.name"
                        >
                        </CInput>
                      </CCol>
                      <CCol sm="3">
                        <CInput
                          prepend="Value"
                          type="text"
                          :value.sync="item.value"
                        >
                        </CInput>
                      </CCol>
                      <CCol sm="2">
                        <CSelect
                          :options="['A','AAAA', 'CNAME', 'PTR']"
                          prepend="DNS Type"
                          :value.sync="item.type"
                        />
                      </CCol>
                      <CCol sm="2">
                        <CInput
                          prepend="TTL"
                          type="number"
                          :min="minTTL"
                          :value.sync="item.ttl"
                        >
                        </CInput>
                      </CCol>
                      <CCol sm="2">
                        <CSelect
                          :options="providerLine"
                          prepend="Line"
                          :value.sync="item.lineValue"
                        />
                      </CCol>
                      <CCol sm="12">
                        <CTextarea
                          prepend="Remark"
                          type="text"
                          :value.sync="item.remark"
                        >
                        </CTextarea>
                      </CCol>
                      <CCol sm="12" align="center">
                        <CButtonGroup>
                          <CButton
                            color="primary"
                            square
                            @click="editRecord(item)"
                          >
                            Submit Edit
                          </CButton>
                          <CButton
                            color="danger"
                            square
                            @click="deleteRecord(item.recordID)"
                          >
                            Delete Record
                          </CButton>
                        </CButtonGroup>
                      </CCol>
                    </CRow>
                  </CCardBody>
                </CCollapse>
              </template>
            </CDataTable>
            <CElementCover
              :boundaries="[{ sides: ['top', 'left'], query: '.media-body' }]"
              :opacity="0.8"
              v-if="loadingData"
            >
              <h1 class="d-inline">Loading... </h1>
              <CSpinner size="5xl" color="success" />
            </CElementCover>
          </CCol>
          <CCol sm="12" align="center">
            <p>Now page:{{ activePage }}</p>
          </CCol>
          <CCol sm="12">
            <CButton
              color="success"
              square
              block
              @click="addRecordModal=true"
            >
              Add Record
            </CButton>
          </CCol>
          <CCol sm="12"><p></p></CCol>
          <CCol sm="12">
            <CPagination
              @update:activePage="loadRecords"
              :active-page.sync="activePage"
              :pages="totalPage"
              align="center"
            />
          </CCol>
        </CRow>
      </CCardBody>
      <CModal
        title="Message"
        color="primary"
        centered
        :show.sync="requestMsgModal"
      >
        {{ requestMsg }}
        <template #footer>
          <CButton @click="requestMsgModal=false" color="primary">Close</CButton>
        </template>
      </CModal>
      <CModal
        title="Add record"
        color="primary"
        size="lg"
        centered
        :show.sync="addRecordModal"
      >
        <CRow>
          <CCol sm="12">
            <CInput
              placeholder="Like www without '.'"
              prepend="Dns Name"
              :append="'.'+domain"
              type="text"
              :value.sync="newRecordName"
            />
          </CCol>
          <CCol sm="12">
            <CSelect
              placeholder="Type"
              :options="['A','AAAA', 'CNAME', 'PTR']"
              prepend="Type"
              :value.sync="newRecordType"
            />
          </CCol>
          <CCol sm="12">
            <CInput
              placeholder="Like 1.1.1.1 or cname.baidu.com"
              prepend="DNS Value"
              type="text"
              :value.sync="newRecordValue"
            >
            </CInput>
          </CCol>
          <CCol sm="12">
            <CSelect
              :options="providerLine"
              prepend="Resolution Line"
              :value.sync="newRecordLine"
            />
          </CCol>
          <CCol sm="12">
            <CInput
              prepend="DNS TTL"
              type="number"
              :min="minTTL"
              :value.sync="newRecordTTL"
            >
            </CInput>
          </CCol>
        </CRow>
        <template #footer>
          <CButtonGroup>
            <CButton @click="addRecord" color="success">Create Record</CButton>
            <CButton @click="addRecordModal=false" color="danger">Cancel</CButton>
          </CButtonGroup>
        </template>
      </CModal>
      <CToaster :autohide="3000">
        <template v-for="toast in fixedToasts">
          <CToast
            :key="'toast' + toast"
            :show="true"
            :color="alertLevel"
            header="Alert"
          >
            {{ returnMsg }}
          </CToast>
        </template>
      </CToaster>
    </CCard>
  </div>
</template>

<script>
const dnsRecordListFields = [
  { key: "type", label: "DNS Type" },
  { key: "name", label: "Record Name" },
  { key: "value", label: "Record Value" },
  { key: "line", label: "Provider Line" },
  { key: "ttl", label: "DNS TTL" },
  { key: "remark", label: "Remark" },
  {
    key: "action",
    label: "Action",
    sorter: false,
    filter: false,
    _style: "width: 20%"
  }
];

export default {
  name: "DomainView",
  data() {
    return {
      domainId: this.$route.params.id,
      domain: null,
      searchKey: null,
      fixedToasts: 0,
      returnMsg: "",
      alertLevel: "success",
      requestMsgModal: false,
      addRecordModal: false,
      loadingData: false,
      requestMsg: null,
      dnsRecordListFields,
      recordList: [],
      activePage: 1,
      totalPage: 1,
      collapseDuration: 0,
      minTTL: 6000,
      providerLine: [],
      newRecordName: null,
      newRecordType: "A",
      newRecordValue: null,
      newRecordLine: null,
      newRecordTTL: 600
    };
  },
  mounted: function() {
    this.onLoadPage();
  },
  methods: {
    onLoadPage: function() {
      this.activePage = 1;
      this.totalPage = 1;
      this.loadRecords(this.activePage);
    },
    loadRecords: function(newPage) {
      this.loadingData = true;
      this.$axios.get("user/domain/" + this.domainId + "/record/list", {
        params: {
          page: newPage,
          per_page: 10,
          search_key: this.searchKey
        }
      })
        .then((response) => {
          if (response.data.code === 200) {
            this.totalPage = response.data.total_pages;
            const providerField = response.data.provider.line_field;
            const nameField = response.data.provider.name_field;
            const recordIDField = response.data.provider.record_id_field;
            const valueField = response.data.provider.value_field;
            const dnsTypeField = response.data.provider.dns_type_field;
            const remarkField = response.data.provider.remark_field;
            const ttlField = response.data.provider.ttl_field;
            const providerLineField = response.data.provider.line_field;
            this.minTTL = response.data.provider.min_ttl;
            this.newRecordTTL = this.minTTL;
            this.domain = response.data.domain;
            let providerLineMap = {};
            response.data.provider.lines.map(item => {
              providerLineMap[item.line_value] = item.line_name;
            });
            this.providerLine = response.data.provider.lines.map(item => {
              return {
                label: item.line_name,
                value: item.line_value
              };
            });
            this.providerLine.unshift(
              { value: null, label: "Select dns line", disabled: true }
            );
            console.log(this.newRecordLine);
            this.recordList = response.data.records.map((item, id) => {
              return {
                ...item,
                line: providerLineMap[item[providerField]],
                name: item[nameField],
                value: item[valueField],
                recordID: item[recordIDField],
                remark: item[remarkField],
                type: item[dnsTypeField],
                ttl: item[ttlField],
                lineValue: item[providerLineField].toString(),
                id
              };
            });
          }
          this.loadingData = false;

        });
      this.activePage = newPage;
    },
    toggleEditRecord(item) {
      if (!this.isSupportEditCheck(item.type)) {
        this.requestMsg = "Not supported edit record type!";
        this.requestMsgModal = true;
        return;
      }
      this.$set(this.recordList[item.id], "_toggled", !item._toggled);
      this.collapseDuration = 300;
      this.$nextTick(() => {
        this.collapseDuration = 0;
      });
    },
    deleteRecord: function(recordID) {
      this.$axios.delete(
        "user/domain/" + this.domainId + "/record/" + recordID + "/delete"
      )
        .then((response) => {
          this.requestMsg = response.data.msg;
          this.requestMsgModal = true;
          if (response.data.code === 200) {
            this.onLoadPage();
          }
        });
    },
    editRecord: function(item) {
      this.$axios.post(
        "user/domain/" + this.domainId + "/record/" + item.recordID + "/edit",
        {
          dns_type: item.type,
          dns_name: item.name,
          dns_value: item.value,
          provider_line: item.lineValue,
          ttl: item.ttl,
          remark: item.remark
        }
      )
        .then((response) => {
          this.requestMsg = response.data.msg;
          this.requestMsgModal = true;
          if (response.data.code === 200) {
            this.onLoadPage();
          }
        });
    },
    addRecord: function() {
      this.addRecordModal = false;
      this.$axios.post(
        "user/domain/" + this.domainId + "/record/add",
        {
          dns_type: this.newRecordType,
          dns_name: this.newRecordName,
          dns_value: this.newRecordValue,
          provider_line: this.newRecordLine,
          ttl: this.newRecordTTL
        }
      )
        .then((response) => {
          this.requestMsg = response.data.msg;
          this.requestMsgModal = true;
          if (response.data.code === 200) {
            this.onLoadPage();
          }
        });
    },
    isSupportEditCheck: function(dnsType) {
      dnsType = dnsType.toUpperCase()
      if (dnsType === "A" || dnsType === "AAAA" || dnsType === "CNAME"
        || dnsType === "PTR") {
        return true;
      } else {
        return false;
      }
    }
  }
};
</script>