<template>
  <div>
    <CCard>
      <CCardHeader>
        Add Domain
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol md="4" sm="12">
            <CInput
              placeholder="Domain"
              prepend="Domain"
              :value.sync="newDomain"
            />
          </CCol>
          <CCol md="4" sm="12">
            <CInput
              placeholder="email or secretid.."
              prepend="Provider secret ID"
              :value.sync="newDomainSecretID"
            />
          </CCol>
          <CCol md="4" sm="12">
            <CInput
              prepend="Provider secret key"
              :value.sync="newDomainSecretKey"
            />
          </CCol>
          <CCol md="4" sm="12">
            <CSelect
              placeholder="Select Provider"
              :options="providerList"
              prepend="Provider"
              :value.sync="newDomainProvider"
            />
          </CCol>
          <CCol md="6" sm="12">
            <CInput
              prepend="Domain ID @provider"
              :value.sync="newDomainProviderID"
            >
              <template #append>
                <CButton
                  color="info"
                  square
                  @click="getProviderDomainID"
                >
                  Try To Get ID
                </CButton>
              </template>
            </CInput>
          </CCol>
          <CCol md="2" sm="12">
            <CButton
              color="primary"
              square
              @click="addDomain"
            >
              Submit
            </CButton>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
    <CCard>
      <CCardHeader>
        Domain List
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol sm="12">
            <CDataTable
              hover
              small
              fixed
              table-filter
              sorter
              items-per-page-select
              :items-per-page="15"
              :fields="domainListFields"
              :items="domainList"
              :noItemsView="{ noItems: 'No Item~' }"
              pagination
            >
              <template #action="{item}">
                <td class="py-2">
                  <CButtonGroup>
                    <CButton
                      color="primary"
                      square
                      @click="$router.push('/user/domain/'+item.id)"
                    >
                      Manage
                    </CButton>
                    <CButton
                      color="info"
                      square
                    >
                      Modify
                    </CButton>
                    <CButton
                      color="danger"
                      square
                    >
                      Delete
                    </CButton>
                  </CButtonGroup>
                </td>
              </template>
            </CDataTable>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
    <CToaster :autohide="3000">
      <template v-for="toast in fixedToasts">
        <CToast
          :key="'toast' + toast"
          :show="true"
          :color="alertLevel"
          header="Alert"
        >
          {{ returnMsg }}
        </CToast>
      </template>
    </CToaster>
    <CModal
      title="Message"
      color="primary"
      :show.sync="requestMsgModal"
    >
      <CCard>
        <CCardBody>
          <CRow>
            <CCol sm="12">
              {{ requestMsg }}
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
      <template #footer>
        <CButton @click="requestMsgModal=false" color="primary">Close</CButton>
      </template>
    </CModal>
    <CModal
      title="Edit domain"
      color="primary"
      :show.sync="editDomainModal"
    >

      <template #footer>
        <CButtonGroup>
          <CButton @click="editDomainModal=false" color="danger">Submit</CButton>
          <CButton @click="editDomainModal=false" color="success">Cancel</CButton>
        </CButtonGroup>
      </template>
    </CModal>
  </div>
</template>

<script>
const domainListFields = [
  { key: "id", label: "ID" },
  { key: "domain", label: "Domain" },
  { key: "provider", label: "Domain Provider Name" },
  { key: "provider_domain_id", label: "Domain id @provider" },
  {
    key: "action",
    label: "Action",
    sorter: false,
    filter: false,
    _style: "width: 20%"
  }
];

export default {
  name: "DomainList",
  data() {
    return {
      fixedToasts: 0,
      returnMsg: "",
      alertLevel: "success",
      requestMsgModal: false,
      editDomainModal: false,
      requestMsg: null,
      domainListFields,
      domainList: [],
      providerList: [],
      newDomain: null,
      newDomainSecretID: null,
      newDomainSecretKey: null,
      newDomainProvider: null,
      newDomainProviderID: null
    };
  },
  mounted: function() {
    this.onLoadPage();
  },
  methods: {
    onLoadPage: function() {
      this.$axios.get("user/domain/list", {})
        .then((response) => {
          if (response.data.code === 200) {
            this.domainList = response.data.domains.map(item => {
              return {
                ...item,
                provider: item.domain_provider.provider_name
              };
            });
          }
        });
      this.$axios.get("user/domain/provider/list", {})
        .then((response) => {
          if (response.data.code === 200) {
            this.domainList = response.data.providers.map(item => {
              this.providerList.push({ value: item.id, label: item.provider_name });
            });
          }
        });
      this.providerList.unshift(
        { value: null, label: "Select a provider", disabled: true }
      );
    },
    getProviderDomainID: function() {
      this.$axios.post("user/domain/provider/domain-id", {
        domain: this.newDomain,
        secret_id: this.newDomainSecretID,
        secret_key: this.newDomainSecretKey,
        provider_id: this.newDomainProvider
      })
        .then((response) => {
          if (response.data.code === 200) {
            this.newDomainProviderID = response.data.provider_domain_id;
          } else if (typeof response.data.code !== "undefined") {
            this.requestMsg = response.data.msg;
            this.requestMsgModal = true;
          }
        });
    },
    addDomain: function() {
      this.$axios.post("user/domain/add", {
        domain: this.newDomain,
        secret_id: this.newDomainSecretID,
        secret_key: this.newDomainSecretKey,
        provider_id: this.newDomainProvider,
        provider_domain_id: this.newDomainProviderID
      })
        .then((response) => {
          this.requestMsg = response.data.msg;
          this.requestMsgModal = true;
          if (response.data.code === 200) {
            this.onLoadPage();
          }
        });
    }
  }
};
</script>