<template>
  <CCard>
    <CCardHeader>
      DDNS List
    </CCardHeader>
    <CCardBody>
      <CRow>
        <CCol sm="12">
          <CDataTable
            hover
            small
            fixed
            table-filter
            sorter
            items-per-page-select
            :items-per-page="15"
            :fields="ddnsListFields"
            :items="ddnsList"
            :noItemsView="{ noItems: 'No Item~' }"
            pagination
          >
            <template #ip="{item}">
              <td class="py-2">
                <p>{{ item.dns_type }}=>{{ item.ip }}</p>
              </td>
            </template>
            <template #action="{item}">
              <td class="py-2">
                <CButtonGroup>
                  <CButton
                    color="primary"
                    square
                    @click="generateDDNSProviderID(item.id)"
                    v-if="item.provider_record_id === null"
                  >
                    GenerateID
                  </CButton>
                  <CButton
                    color="primary"
                    square
                    @click="viewItem=item;
                    getDDNSHistory(item.id);
                    viewRecordModal=true;"
                    v-if="item.provider_record_id !== null"
                  >
                    View
                  </CButton>
                  <CButton
                    color="dark"
                    square
                  >
                    Edit
                  </CButton>
                  <CButton
                    color="danger"
                    square
                    @click="deleteDDNSRecord(item.id)"
                  >
                    Delete
                  </CButton>
                </CButtonGroup>
              </td>
            </template>
          </CDataTable>
        </CCol>
      </CRow>
    </CCardBody>
    <CToaster :autohide="3000">
      <template v-for="toast in fixedToasts">
        <CToast
          :key="'toast' + toast"
          :show="true"
          :color="alertLevel"
          header="Alert"
        >
          {{ returnMsg }}
        </CToast>
      </template>
    </CToaster>
    <CModal
      title="Message"
      color="primary"
      centered
      :show.sync="requestMsgModal"
    >
      {{ requestMsg }}
      <template #footer>
        <CButton @click="requestMsgModal=false" color="primary">Close</CButton>
      </template>
    </CModal>
    <CModal
      title="View Record"
      color="primary"
      centered
      size="lg"
      :show.sync="viewRecordModal"
    >
      <CRow>
        <CCol sm="12">
          <CInput
            :value="viewItem.uuid"
          >
            <template #prepend-content>
              <CCardText>Record UUID</CCardText>
            </template>
          </CInput>
          <CInput
            :value="viewItem.provider_record_id"
          >
            <template #prepend-content>
              <CCardText>Provider ID</CCardText>
            </template>
          </CInput>
        </CCol>
        <CCol sm="12">
          <CTabs>
            <CTab title="DDNS record">
              <CDataTable
                hover
                small
                fixed
                table-filter
                :items-per-page="5"
                :fields="ddnsHistoryListFields"
                :items="ddnsHistoryList"
                :noItemsView="{ noItems: 'No Item~' }"
                pagination
              >

              </CDataTable>
              <CElementCover
                :boundaries="[{ sides: ['top', 'left'], query: '.media-body' }]"
                :opacity="0.8"
                v-if="loadingData"
              >
                <h1 class="d-inline">Loading... </h1>
                <CSpinner size="5xl" color="success" />
              </CElementCover>
            </CTab>
            <CTab title="Callback">
              <CCard>
                <CCardBody>
                  <CCol sm="12">
                    <CCardText>
                      Linux(Curl)
                    </CCardText>
                    <code>
                      curl 'https://network.htroy.com/api/callback/ddns/{{viewItem.uuid}}/update?mail={{userMail}}&token={{userToken}}' --interface $your_interface -4/6
                    </code>
                  </CCol>
                  <CCol>
                    <CCardText>
                      Mikrotik(Fetch)
                    </CCardText>
                    <code>
                      /tool fetch url="https://network.htroy.com/api/callback/ddns/{{viewItem.uuid}}/update?mail={{userMail}}&token={{userToken}}" output=none
                    </code>
                  </CCol>
                </CCardBody>
              </CCard>
            </CTab>
          </CTabs>
        </CCol>
      </CRow>
      <template #footer>
        <CButton @click="viewRecordModal=false" color="primary">Close</CButton>
      </template>
    </CModal>
  </CCard>
</template>

<script>
const ddnsListFields = [
  { key: "id", label: "ID" },
  { key: "device_name", label: "Device Name" },
  { key: "interface_name", label: "Interface Name" },
  { key: "ddns_type", label: "DDNS Type" },
  { key: "domain", label: "Domain", _style: "max-width: 100x"},
  { key: "ip", label: "Now IP" },
  { key: "provider", label: "Provider"},
  { key: "provider_line", label: "Line"},
  { key: "updated_at", label: "Last Update" },
  {
    key: "action",
    label: "Action",
    sorter: false,
    filter: false,
    _style: "width: 20%"
  }
];

const ddnsHistoryListFields = [
  { key: "ip", label: "IP" },
  { key: "created_at", label: "Report Time" }
];

export default {
  name: "DDNSList",
  data() {
    return {
      fixedToasts: 0,
      returnMsg: "",
      alertLevel: "success",
      requestMsgModal: false,
      viewRecordModal: false,
      viewItem: [],
      currentAlertCounter: 0,
      requestMsg: null,
      ddnsListFields,
      ddnsList: [],
      ddnsHistoryListFields,
      ddnsHistoryList: [],
      loadingData: false,
      userMail: null,
      userToken: null
    };
  },
  mounted: function() {
    this.onLoadPage();
  },
  methods: {
    onLoadPage: function() {
      this.$axios.get("user/ddns/list", {})
        .then((response) => {
          if (response.data.code === 200) {
            this.ddnsList = response.data.instances.map(item => {
              return {
                ...item,
                interface_name: item.interface.interface_name,
                device_name: item.device.name,
                domain: item.domain_prefix+"."+item.domain.domain,
                ddns_type: item.ddns_type.type_name,
                provider: item.domain.domain_provider.provider_name,
                provider_line: item.provider_line.line_name
              }
            });
          }
        });
    },
    generateDDNSProviderID: function(id) {
      this.$axios.post("user/ddns/" + id + "/generate-provider-id", {})
        .then((response) => {
          this.requestMsg = response.data.msg;
          this.requestMsgModal = true;
          if (response.data.code === 200) {
            this.onLoadPage();
          }
        });
    },
    deleteDDNSRecord: function(id) {
      this.$axios.delete("user/ddns/" + id + "/delete", {})
        .then((response) => {
          this.requestMsg = response.data.msg;
          this.requestMsgModal = true;
          if (response.data.code === 200) {
            this.onLoadPage();
          }
        });
    },
    getDDNSHistory: function(id) {
      this.loadingData = true;
      this.$axios.get("user/ddns/" + id + "/history", {})
        .then((response) => {
          if (response.data.code === 200) {
            this.ddnsHistoryList = response.data.history;
            this.userMail = response.data.mail;
            this.userToken = response.data.token;
            this.loadingData = false;
          }
        });
    },
  }
};
</script>